import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Page not found" pathName="/404" />
    <section>
      <div className="container py-6">
        <div className="row mb-5">
          <div className="col">
            <h1>No results found</h1>
            <p>The page you requested could not be found. Try refining your search, or use the navigation above to locate the post.</p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage